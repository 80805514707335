$primaryColor: #00C897;

.btn {
  padding: 0.8rem 2.5rem;
  font-weight: 700;
  color: white;
  background-color: #00C897;
  @media screen and (max-width:575px) {
    padding: .810rem 1rem;
  }
  
}
.btn:hover{
  color: white;

}