// Header
.header-column{
  a{
    text-decoration: none;
  }
  h2{
  @media screen and (max-width: 574px) {
    font-size: 18px;
    
  }
  }
  .navbar-nav{
    flex-direction: row;
    align-items: center;
    .btn{
      font-size: 14px;
      padding: 0.65rem 2rem;
      @media screen and (max-width:575px) {
        padding: 0.65rem 0.8rem
      }
    }
    a:not(.btn){
      padding: 0 0.85em;
    }
  }
  li{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
  }
}
// Why Choose Us Section
.whyUs{
  .subtitle{
    font-weight: 500;
    font-size: 20px;
    line-height: 34px;
   
  }
  .featured-box{
    display: flex;
    justify-content: center;
  
  }
  .featured-box-content{
    padding: 0 20px;
    
    p{
      color: $primaryColor;
    }
  }

}
// As Simple as 1-2-3 
.steps{
  background-color: #212121;
  color: white;
  .icon{
    padding: 15px 35px;
    border: 10px solid #00C897;
    border-radius: 50%;
    color: white;
    font-size: 50px;
    font-weight: 700;
    font-family: 'Rubik';
  }
}

// What Can You Do Section
.section4{
  .featured-box{
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 40px 0;
    @media screen and (max-width:662px){
      margin : 0 25px;
    }
  }
  .featured-box-icon {
    font-size: 50px;
    margin: 54px 0px;
  }
  h3{
    color: #00c897;
    font-weight: 700;
  }
}

// Footer
.footer{
  .footer-copyright {
    border-top: 1px solid #e2e8ea;
    padding: 0px 0px;
    color: #67727c;
    a{
      color: #A4A4A4;
      text-decoration: none;
    }
}
}
