section{
  padding: 5rem 0;
}
h2{
  font-weight: 700;
  font-size: 40px;
  line-height: 53px;
  @media screen and (max-width: 767px){
    font-size: 32px
  }
 
}
p{
  font-size: 17px;
  @media screen and (max-width: 767px) {
      font-size: 15px;
  }
}
h3{
  font-size: 22px;
}